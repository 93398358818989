import fetch from 'common/js/fetch';

// import {  } from 'common/js/config';

/**
 * 获取数据字典列表
 * @param parentKey
 * @param bizType
 */
export function getDictList({ parentKey, bizType = '/core/v1/dict/list' }) {
  if (getDictList[parentKey]) {
    return Promise.resolve(getDictList[parentKey]);
  }
  return fetch(bizType, {
    parentKey
  }).then((data) => {
    let tmpl = [];
    data.forEach((v, i) => {
      tmpl.push({
        id: v.id,
        dkey: v.key,
        dvalue: v.value
      });
    });
    getDictList[parentKey] = tmpl;
    return tmpl;
  });
}

// 根据parentKeyList查数据字典
export function getDictAllList(parentKeyList) {
  return fetch('/core/v1/dict/list', { parentKeyList });
}

/**
 * 根据ckey查询系统参数
 * @param key
 * @param bizType
 */
export function getSystormParam({ key, bizType = '/core/v1/config/list' }) {
  if (getSystormParam[key]) {
    return Promise.resolve(getSystormParam[key]);
  }
  return fetch(bizType, {
    key
  }).then((data) => {
    getSystormParam[key] = data;
    return data;
  });
}
