export const SYSTEM_CODE = 'CD-OGC000019';
// 七牛上传地址
export const UPLOAD_URL = 'http://up.qiniup.com';
// 阿里云图片前缀
export const PIC_PREFIX = 'http://aws-prod.oss-accelerate.aliyuncs.com';
export const PIC_BASEURL_L = '?imageMogr2/auto-orient/thumbnail/!1000x1000r';

// 系统userid
export const SYS_USER = 'SYS_USER';

// 系统根菜单编号
export const ROOT_MENU_CODE = 'COINSM201700000000000000';

// FMVP 币种
export const CION_FMVP = 'FMVP';

export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 15 }
  }
};
export const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};

export const tailFormItemLayout1 = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 19,
      offset: 5
    }
  }
};

export const lineFormItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 18}
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 20}
  }
};

// 定义 validateFieldsAndScroll 的滚动行为
export const validateFieldsAndScrollOption = {
  scroll: {
    offsetTop: 110
  }
};

export const DATE_FORMAT = 'YYYY-MM-DD';
export const MONTH_FORMAT = 'YYYY-MM';
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DAY_TIME = 'HH:mm';
export const DAY_TIMES = 'HH:mm:ss';
export const DATE_HOURS_M = 'YYYY-MM-DD HH:mm';

// 生成小程序二维码地址
export const APPLET_CASE_DETAIL_URL = '/pagesCase/pages/case/detail/detail';
export const APPLET_PRODUCT_DETAIL_URL = '/pagesProduct/pages/product/detail/detail';
