import cookies from 'browser-cookies';
import axios from 'axios';
import { clearUser, showErrMsg, isUndefined } from './util';

const ERR_OK = '200';

export default function fetch(code, param = {}) {
  const url = '/api' + code;
  const lang = param.lang || 'zh_CN';
  delete param.lang;
  const data = {
    ...param
  };
  param = JSON.stringify(data);
  return axios({
    withCredentials: true,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: cookies.get('token') || '',
      'Accept-language': lang
    },
    url: url,
    data: param
  }).then((res) => {
    res = res.data;
    // token 错误
    if (res.errorCode === '300000') {
      logout();
      return Promise.reject('timeout');
    }
    // 用户认证失败
    if (res.errorCode === 'A50004') {
      logout(res.errorMsg);
      return Promise.reject('timeout');
    }
    if (res.code !== ERR_OK) {
      if (res.errorMsg) {
        showErrMsg(res.errorMsg.toString());
      } else {
        showErrMsg('操作失败');
      }
      return Promise.reject(res);
    }
    return Promise.resolve(isUndefined(res.data) ? res : res.data);
  });
}

export function logout(msg = '登录失效，请重新登录!') {
  clearUser();
  showErrMsg(msg);
  if (window.location.href.indexOf('/login') === -1) {
    setTimeout(() => {
      window.location.href = '/login';
    }, 800);
  }
}
