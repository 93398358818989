import fetch from 'common/js/fetch';

// 加载七牛token
export function getQiniuToken() {
  return fetch('/v1/common/get_qiniu_token');
}

// 获取阿里token
export function getAlToken() {
  return fetch('/core/v1/common/public/get_ali_token');
}

// 列表条件查询城市
export function getCityList(config = {}) {
  return new Promise((resolve) => {
    fetch('/core/v1/area/public/list', config).then((data) => {
      const cityData = [];
      let pObj = {};
      let cList = [];
      let list = [];
      for (let i = 0, len = data.length; i < len; i++) {
        // console.log(len);
        const { id, pid, deep } = data[i];
        // console.log(deep, 'deep');
        if (+deep === 2) {
          pObj = {
            value: data[i].name,
            parentId: pid.toString(),
            id: id.toString(),
            label: data[i].name,
            children: []
          };
          cityData.push(pObj);
        } else if (+deep === 3) {
          cList.push({
            value: data[i].name,
            parentId: pid.toString(),
            id: id.toString(),
            label: data[i].name,
            children: []
          });
          // console.log(cityData, 'cityData');
        } else if (+deep === 4) {
          list.push({
            value: data[i].name,
            parentId: pid.toString(),
            id: id.toString(),
            label: data[i].name,
            children: []
          });
        }
      }
      // console.log(cList, 'JSON.stringify(cityData)');
      for (let i = 0, len = cityData.length; i < len; i++) {
        for (let j = 0, cLen = cList.length; j < cLen; j++) {
          if (cityData[i].id === cList[j].parentId) {
            cityData[i].children.push(cList[j]);
          }
        }
      }
      for (let j = 0, cLen = cList.length; j < cLen; j++) {
        for (let k = 0, l = list.length; k < l; k++) {
          if (cList[j].id === list[k].parentId) {
            cList[j].children.push(list[k]);
          }
        }
      }
      sessionStorage.setItem('cityData', JSON.stringify(cityData));
      // console.log(cityData, 'JSON.stringify(cityData)');
      resolve(cityData);
    });
  });
}

// 列表条件查询易宝城市
export function getYbCityList(config = {}) {
  return new Promise((resolve) => {
    fetch('/core/v1/market_yeepay_area/public/list', config).then((data) => {
      const cityData = [];
      let pObj = {};
      let cList = [];
      let list = [];
      for (let i = 0, len = data.length; i < len; i++) {
        // console.log(len);
        const { id, pid, deep } = data[i];
        // console.log(deep, 'deep');
        if (+deep === 2) {
          pObj = {
            value: data[i].code,
            parentId: pid.toString(),
            id: id.toString(),
            label: data[i].name,
            children: []
          };
          cityData.push(pObj);
        } else if (+deep === 3) {
          cList.push({
            value: data[i].code,
            parentId: pid.toString(),
            id: id.toString(),
            label: data[i].name,
            children: []
          });
          // console.log(cityData, 'cityData');
        } else if (+deep === 4) {
          list.push({
            value: data[i].code,
            parentId: pid.toString(),
            id: id.toString(),
            label: data[i].name,
            children: []
          });
        }
      }
      // console.log(cList, 'JSON.stringify(cityData)');
      for (let i = 0, len = cityData.length; i < len; i++) {
        for (let j = 0, cLen = cList.length; j < cLen; j++) {
          if (cityData[i].id === cList[j].parentId) {
            cityData[i].children.push(cList[j]);
          }
        }
      }
      for (let j = 0, cLen = cList.length; j < cLen; j++) {
        for (let k = 0, l = list.length; k < l; k++) {
          if (cList[j].id === list[k].parentId) {
            cList[j].children.push(list[k]);
          }
        }
      }
      resolve(cityData);
    });
  });
}
