import cookies from 'browser-cookies';
import { message, Modal } from 'antd';
import moment from 'moment';
import {
  PIC_PREFIX,
  DATE_FORMAT,
  MONTH_FORMAT,
  DATETIME_FORMAT,
  DAY_TIME, DAY_TIMES
} from './config';
import './lib/BigDecimal';
import { getAlToken, getCityList } from 'api/general';
import fetch from 'common/js/fetch';

/**
 * 保存用户登录信息
 * @param userId
 * @param token
 */
export function setUser({ token, userId }) {
  cookies.set('token', token);
  cookies.set('userId', userId);
}

// 删除用户登录信息
export function clearUser() {
  cookies.erase('roleMenuCode');
  cookies.erase('userName');
  cookies.erase('type');
  cookies.erase('token');
  cookies.erase('parentId');
  cookies.erase('realName');
  localStorage.clear();
  sessionStorage.clear();
}

// 设置用户角色信息
export function setRoleInfo({ kind, loginName }) {
  cookies.set('userName', loginName);
  // cookies.set('realName', realName);
  cookies.set('type', kind);
}

export function getUserId() {
  return cookies.get('userId');
}

// 获取用户角色编号
export function getRoleCode() {
  return cookies.get('roleCode');
}

// 获取用户username
export function getUserName() {
  return cookies.get('userName');
}

// 获取用户realName
export function getUserRealName() {
  return cookies.get('realName');
}

// 获取用户parentId
export function getUserParentId() {
  return cookies.get('parentId');
}

// 获取根目录编号
export function getRootMenuCode() {
  return cookies.get('roleMenuCode');
}

// 获取用户type
export function getUserType() {
  return cookies.get('type');
}

/**
 * 通过正则表达式获取URL传递参数
 * @param name
 * @returns
 */
export function getQueryString(name, search) {
  search = search || window.location.search;
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  var r = search.substr(1).match(reg);
  if (r !== null) {
    return decodeURIComponent(r[2]);
  }
  return '';
}

/**
 * 通过Route定义方式,通配符传参传递参数
 * @param name
 * @returns
 */
export function getQueryRouteParams(name, params) {
  return params[name];
}

/**
 * 获取正确的url，使其以'/'开头
 * @param url
 */
export function getRealUrl(url) {
  if (url && url !== '#') {
    url = /^\//.test(url) ? url : '/' + url;
  }
  return url;
}

/**
 * 日期格式转化
 * @param date
 * @param fmt
 */
export function formatDate(date, fmt = 'yyyy-MM-dd') {
  if (isUndefined(date)) {
    return '-';
  }
  date = new Date(date);
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    );
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + '';
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str)
      );
    }
  }
  return fmt;
}

/**
 * 获取两位格式化数字
 * @param str
 */
export function padLeftZero(str) {
  return ('00' + str).substr(str.length);
}

/**
 * 日期格式转化 yyyy-MM-dd
 * @param date
 * @param format
 */
export function dateFormat(date) {
  return formatDate(date, 'yyyy-MM-dd');
}

/**
 * 日期格式转化 yyyy-MM
 * @param date
 * @param format
 */
export function monthFormat(date) {
  date = formatDate(date, 'yyyy-MM-dd');
  let arr = date.split('-');
  arr.length = 2;
  date = arr.join('-');
  return date;
}

/**
 * 日期格式转化 yyyy-MM-dd hh:mm:ss
 * @param date
 * @param format
 */
export function dateTimeFormat(date) {
  return formatDate(date, 'yyyy-MM-dd hh:mm:ss');
}

/**
 * 金额格式转化
 * @param money 金额
 * @param format 小数点后几位
 * @param isRe 是否转化千分位格式显示：34,345.23
 */
// export function moneyFormat(money, format = 2, isRe = false) {
//   if (money === '0') {
//     return '0';
//   }
//   let flag = false; // 是否是负数
//   if (typeof money === 'undefined' || isNaN(money)) {
//     return '';
//   }
//   money = +money;
//   if (money < 0) {
//     money = -1 * money;
//     flag = true;
//   }
//   money = money / 1000;
//   money = money.toFixed(format);
//   let moneyArr = money.split('.');
//   // 千分位转化
//   if (isRe) {
//     var re = /\B(?=(\d{3})+$)/g;
//     moneyArr[0] = moneyArr[0].replace(re, ',');
//   }
//   money = moneyArr.join('.');
//   if (flag) {
//     money = '-' + money;
//   }
//   return money;
// }
/**
 * 金额格式转化 根据币种格式化金额
 * @param money 金额
 * @param format 小数点后几位
 * @param coin 币种
 * @param noComma 是否千分化
 * @param noZero 是否去零
 */
export function moneyFormat(
  money,
  format,
  coin,
  noComma = false,
  noZero = false
) {
  if (+money === 0) {
    return 0;
  }
  let unit = coin && getCoinData()[coin] ? getCoinUnit(coin) : '1000';
  if (isNaN(money)) {
    return '-';
  } else {
    Number(money);
  }
  return formatMoney(money, format, unit, noComma, noZero);
}
/**
 * 金额格式转化 根据币种格式化金额
 * @param money 金额
 * @param format 小数点后几位
 * @param unitNum 格式化位数
 * @param noComma 是否千分化
 * @param noZero 是否去零
 */
export function formatMoney(
  money,
  format,
  unitNum,
  noComma = false,
  noZero = false
) {
  let unit = !unitNum ? '1000' : unitNum;
  let flag = false; // 是否是负数
  if (isNaN(money)) {
    return '-';
  } else {
    Number(money);
  }
  if (money < 0) {
    money = -1 * money;
    flag = true;
  }
  // 如果有币种coin 则默认为8位  如果没有则默认格式为2位小数
  if (unitNum !== '1000') {
    format = 8;
  } else if (isUndefined(format) || typeof format === 'object') {
    format = 2;
  }
  // 金额格式化 金额除以unit并保留format位小数
  money = new BigDecimal(money.toString());
  money = money
    .divide(new BigDecimal(unit), format, MathContext.ROUND_DOWN)
    .toString();
  // 是否去零
  if (noZero) {
    money = money.replace(/(-?\d+)\.0+$/, '$1');
    if (!/^-?\d+$/.test(money)) {
      money = money.replace(/(.+[^0]+)0+$/, '$1');
    }
  }
  // 是否需要千分化
  if (!noComma) {
    var re = /\d{1,3}(?=(\d{3})+$)/g;
    money = money.replace(
      /^(\d+)((\.\d+)?)$/,
      (s, s1, s2) => s1.replace(re, '$&,') + s2
    );
  }
  if (flag) {
    money = '-' + money;
  }
  return money;
}

/**
 * 金额放大 根据币种的单位把金额放大
 * @param money
 * @param rate
 */
export function moneyParse(money, rate) {
  if (isUndefined(money)) {
    return '';
  }
  money = +money;
  rate = rate || 1000;
  return money * rate;
}

/**
 * 把格式化金额去掉逗号
 * @param money
 */
export function moneyReplaceComma(money) {
  return ('' + money).replace(/,/g, '');
}

// 判断是否是数字
export function isNumeric(value) {
  return !isNaN(Number(value));
}

/**
 * 格式化文件地址
 * @param urls
 * @param suffix
 */
export function formatFile(urls, suffix = '') {
  if (!urls) {
    return '';
  }
  let url = urls.split(/\|\|/)[0];
  if (!/^http|^data:image/i.test(url)) {
    let index = url.indexOf('?imageMogr2');
    if (index !== -1) {
      suffix = url.substr(index);
      url = url.substr(0, index);
    }
    const qiniuDomain = localStorage.getItem('qiniu_domain') + '/';
    url = qiniuDomain + url + suffix;
  }
  return url;
}

/**
 * 格式化图片地址
 * @param imgs
 * @param suffix
 */
export function formatImg(
  imgs) {
  return formatFile(imgs);
}

/**
 * 是否是空
 * @param value
 */
export function isUndefined(value) {
  return value === undefined || value === null || value === '';
}

/**
 * 是否是函数
 * @param func
 */
export function isFunc(func) {
  return typeof func === 'function';
}

/*
 * 正常：'{{realName.DATA}}({{mobile.DATA}})'
 * 有层级：'{{realName.DATA}}({{mobile.DATA}})@user'
 */
export function tempString(str, data) {
  let parantName = '';
  if (str.includes('@')) {
    parantName = str.split('@')[1];
    str = str.split('@')[0];
  }
  return str.replace(/\{\{(\w+)\.DATA\}\}/gi, function (matchs) {
    const returns = parantName
      ? data[parantName][matchs.replace(/\{\{(\w+)\.DATA\}\}/, '$1')]
      : data[matchs.replace(/\{\{(\w+)\.DATA\}\}/, '$1')];
    return isUndefined(returns) ? '' : returns;
  });
}

export function showMsg(msg, type = 'success', time = 2) {
  message[type](msg, time);
}

export function showWarnMsg(msg, time = 2) {
  showMsg(msg, 'warning', time);
}

export function showSucMsg(msg, time = 2) {
  showMsg(msg, 'success', time);
}

export function showErrMsg(msg, time = 2) {
  showMsg(msg, 'error', time);
}

export function showConfirm({
  title = '您确定要删除该条记录吗?',
  content = '删除记录后无法还原',
  okType = 'primary',
  onOk,
  onCancel
}) {
  Modal.confirm({
    okType,
    title: title,
    content: content,
    okText: '确定',
    cancelText: '取消',
    onOk() {
      onOk && onOk();
    },
    onCancel() {
      onCancel && onCancel();
    }
  });
}

export function showDelConfirm({ onOk, onCancel }) {
  showConfirm({
    okType: 'danger',
    onOk,
    onCancel
  });
}

export function convertCurrency(currencyDigits) {
  if (isUndefined(currencyDigits)) {
    return '';
  }
  currencyDigits = moneyReplaceComma(currencyDigits);
  if (isNaN(currencyDigits)) {
    return '';
  }
  if (currencyDigits < 0) {
    currencyDigits = -currencyDigits;
  }
  var MAXIMUM_NUMBER = 99999999999.99;
  // Predefine the radix characters and currency symbols for output:
  var CN_ZERO = '零';
  var CN_ONE = '壹';
  var CN_TWO = '贰';
  var CN_THREE = '叁';
  var CN_FOUR = '肆';
  var CN_FIVE = '伍';
  var CN_SIX = '陆';
  var CN_SEVEN = '柒';
  var CN_EIGHT = '捌';
  var CN_NINE = '玖';
  var CN_TEN = '拾';
  var CN_HUNDRED = '佰';
  var CN_THOUSAND = '仟';
  var CN_TEN_THOUSAND = '万';
  var CN_HUNDRED_MILLION = '亿';
  var CN_DOLLAR = '元';
  var CN_TEN_CENT = '角';
  var CN_CENT = '分';
  var CN_INTEGER = '整';
  var integral; // Represent integral part of digit number.
  var decimal; // Represent decimal part of digit number.
  var outputCharacters; // The output result.
  var parts;
  var digits, radices, bigRadices, decimals;
  var zeroCount;
  var i, p, d;
  var quotient, modulus;
  currencyDigits = currencyDigits.toString();
  if (currencyDigits === '') {
    alert('请输入小写金额！');
    return '';
  }
  if (currencyDigits.match(/[^,.\d]/) !== null) {
    alert('');
    return '';
  }
  if (
    currencyDigits.match(
      /^((\d{1,3}(,\d{3})*(.((\d{3},)*\d{1,3}))?)|(\d+(.\d+)?))$/
    ) == null
  ) {
    alert('小写金额的格式不正确！');
    return '';
  }
  currencyDigits = currencyDigits.replace(/,/g, ''); // Remove comma delimiters.
  currencyDigits = currencyDigits.replace(/^0+/, ''); // Trim zeros at the beginning.
  // Assert the number is not greater than the maximum number.
  if (Number(currencyDigits) > MAXIMUM_NUMBER) {
    alert('金额过大，应小于1000亿元！');
    return '';
  }
  // Process the coversion from currency digits to characters:
  // Separate integral and decimal parts before processing coversion:
  parts = currencyDigits.split('.');
  if (parts.length > 1 && !/^0+$/.test(parts[1])) {
    integral = parts[0];
    decimal = parts[1];
    // Cut down redundant decimal digits that are after the second.
    decimal = decimal.substr(0, 2);
  } else {
    integral = parts[0];
    decimal = '';
  }
  // Prepare the characters corresponding to the digits:
  digits = [
    CN_ZERO,
    CN_ONE,
    CN_TWO,
    CN_THREE,
    CN_FOUR,
    CN_FIVE,
    CN_SIX,
    CN_SEVEN,
    CN_EIGHT,
    CN_NINE
  ];
  radices = ['', CN_TEN, CN_HUNDRED, CN_THOUSAND];
  bigRadices = ['', CN_TEN_THOUSAND, CN_HUNDRED_MILLION];
  decimals = [CN_TEN_CENT, CN_CENT];
  // Start processing:
  outputCharacters = '';
  // Process integral part if it is larger than 0:
  if (Number(integral) > 0) {
    zeroCount = 0;
    for (i = 0; i < integral.length; i++) {
      p = integral.length - i - 1;
      d = integral.substr(i, 1);
      quotient = p / 4;
      modulus = p % 4;
      if (d === '0') {
        zeroCount++;
      } else {
        if (zeroCount > 0) {
          outputCharacters += digits[0];
        }
        zeroCount = 0;
        outputCharacters += digits[Number(d)] + radices[modulus];
      }
      if (modulus === 0 && zeroCount < 4) {
        outputCharacters += bigRadices[quotient];
        zeroCount = 0;
      }
    }
    outputCharacters += CN_DOLLAR;
  }
  // Process decimal part if there is:
  if (decimal !== '') {
    for (i = 0; i < decimal.length; i++) {
      d = decimal.substr(i, 1);
      if (d !== '0') {
        outputCharacters += digits[Number(d)] + decimals[i];
      }
    }
  }
  // Confirm and return the final output string:
  if (outputCharacters === '') {
    outputCharacters = CN_ZERO + CN_DOLLAR;
  }
  if (decimal === '') {
    outputCharacters += CN_INTEGER;
  }
  return outputCharacters;
}

/**
 * 金额转换大写
 * @param money
 */
export function moneyUppercase(Num) {
  return convertCurrency(Num);
}

/**
 * 数字转换大写
 * @param number
 */
export function numUppercase(Num) {
  let newNum = convertCurrency(Num);
  newNum = newNum.replace(/元|整/gi, '');
  return newNum;
}

/**
 * 四舍五入保留2位小数（不够位数，则用0替补）
 * @param number
 */
export function keepTwoDecimalFull(num) {
  let number = Math.round(num * 100) / 100;
  return number;
}

// 空函数
export const noop = () => { };

// 获取详情页面控件校验规则
export const getRules = (item) => {
  let rules = [];
  if (item.required && !item.hidden) {
    rules.push({
      required: true,
      message: '必填字段'
    });
  }
  if (item.email) {
    rules.push({
      type: 'email',
      message: '请输入正确格式的电子邮件'
    });
  }
  if (item.mobile) {
    rules.push({
      pattern: /^1[2|3|4|5|6|7|8|9]\d{9}$/,
      message: '手机格式不对'
    });
  }
  if (item.logName) {
    rules.push({
      pattern: /^\w{4,16}$/,
      message: '请输入4-16位由数字、字母或下划线组成的登录名'
    });
  }
  if (item.singleUpCaseLetter) {
    rules.push({
      pattern: /^[A-Z]$/,
      message: '请输入单个大写字母'
    });
  }
  if (item['Z+']) {
    rules.push({
      pattern: /^[1-9]\d*$/,
      message: '请输入正整数'
    });
  }
  if (item['nonnegativeInteger']) {
    rules.push({
      pattern: /^[0-9]+$/,
      message: '请输入大于等于0的整数'
    });
  }
  if (item.number) {
    rules.push({
      pattern: /^-?\d+(\.\d+)?$/,
      message: '请输入合法的数字'
    });
  }
  if (item.positive) {
    rules.push({
      pattern: /^\d+(\.\d+)?$/,
      message: '请输入正数'
    });
  }
  if (item.integer) {
    rules.push({
      pattern: /^-?\d+$/,
      message: '请输入整数'
    });
  }
  if (item.idCard) {
    rules.push({
      pattern: /^([1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3})|([1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X|x))$/,
      message: '请输入合法的身份证号'
    });
  }
  if (item.bankCard) {
    rules.push({
      pattern: /^([1-9]{1})(\d{13,19})$/,
      message: '请输入合法的银行卡号'
    });
  }
  if (item.decimals) {
    rules.push({
      pattern: /^(([1-9]{1}\d*)|(0{1}))(\.\d{0,2})?$/,
      message: '小数点后最多2位'
    });
  }
  if (item.min) {
    rules.push({
      validator: (rule, value, callback) => {
        let reg = /^-?\d+(\.\d+)?$/.test(value);
        if (reg && value && Number(value) < Number(item.min)) {
          let error = `请输入一个最小为${item.min}的值`;
          callback(error);
        } else {
          callback();
        }
      }
    });
  }
  if (item.max) {
    rules.push({
      validator: (rule, value, callback) => {
        let reg = /^-?\d+(\.\d+)?$/.test(value);
        if (reg && value && Number(value) > Number(item.max)) {
          let error = `请输入一个最大为${item.max}的值`;
          callback(error);
        } else {
          callback();
        }
      }
    });
  }
  if (item.maxlength) {
    rules.push({
      min: 1,
      max: item.maxlength,
      message: `请输入一个长度最多是${item.maxlength}的字符串`
    });
  }
  if (item.shijian) {
    rules.push({
      pattern: /^(2[0-3]|[0-1]?\d|\*|-|\/)$/,
      message: '请输入0-23的整数'
    });
  }
  return rules;
};

// 获取修改、详情页每个输入框的真实值
export const getRealValue = ({
  pageData,
  field,
  type,
  _keys,
  value,
  rangedate,
  multiple,
  formatter,
  amount,
  amountRate,
  cFields,
  readonly,
  listCode,
  selectData,
  isDayTime,
  isHMS,
  cityData
}) => {
  let result;
  pageData = isUndefined(pageData) ? {} : pageData;
  result = pageData[field];
  try {
    if (_keys) {
      result = getValFromKeys(_keys, pageData, type);
    }
    if (!isUndefined(value) && !result) {
      result = value;
    }
    if (type === 'citySelect' || type === 'citySelectT') {
      result = getCityVal(_keys, cFields, result, pageData, cityData);
      if (value.length === 3) {
        result = value;
      }
    } else if (type === 'date' || type === 'datetime' || type === 'month') {
      result = getRealDateVal(
        pageData,
        type,
        result,
        _keys,
        readonly,
        rangedate,
        isDayTime,
        isHMS
      );
    } else if (type === 'checkbox') {
      result = getRealCheckboxVal(result);
    } else if (multiple) {
      result = result ? result.split(',') : [];
    } else if (type === 'o2m') {
      if (listCode) {
        result = isUndefined(result) ? selectData[field] : result;
      }
      result = result || [];
    }
    if (formatter) {
      if (type === 'select' && multiple) {
        result = formatter(result, pageData);
        result = result ? result.split(',') : [];
      } else {
        result = formatter(result, pageData);
      }
    } else if (amount) {
      result = isUndefined(result) ? '' : moneyFormat(result, amountRate);
    }
  } catch (e) { }
  return isUndefined(result) ? '' : result;
};

// 通过_keys获取真实值
function getValFromKeys(keys, pageData, type) {
  let _value = { ...pageData };
  let emptyObj = {};
  keys.forEach((key) => {
    _value = isUndefined(_value[key]) ? emptyObj : _value[key];
  });
  return _value === emptyObj
    ? type === 'checkbox' || type === 'citySelect' || type === 'citySelectT' || type === 'o2m'
      ? []
      : ''
    : _value;
}

// 获取城市的真实值
function getCityVal(keys, cFields, result, pageData, cityData) {
  let cData = keys && result ? result : pageData;
  let prov = cData[cFields[0]];
  if (prov) {
    let city = cData[cFields[1]] ? cData[cFields[1]] : '全部';
    let area = cData[cFields[2]] ? cData[cFields[2]] : '全部';
    result = [prov, city, area];
  } else {
    result = [];
  }
  if (cityData && cityData.length) {
    const provInfo = cityData.find(it => it.value === result[0]);
    if (provInfo) {
      result[0] = provInfo.label;
      const cityInfo = provInfo.children.find(it => it.value === result[1]);
      if (cityInfo) {
        result[1] = cityInfo.label;
        const areaInfo = cityInfo.children.find(it => it.value === result[2]);
        if (areaInfo) {
          result[2] = areaInfo.label;
        }
      }
    }
  }
  return result;
}

// 获取日期真实值
function getRealDateVal(
  pageData,
  type,
  result,
  keys,
  readonly,
  rangedate,
  isDayTime,
  isHMS
) {
  let format = isDayTime
    ? DAY_TIME
    : type === 'date'
      ? DATE_FORMAT
      : type === 'month'
        ? MONTH_FORMAT
        : DATETIME_FORMAT;
  let fn =
    type === 'date'
      ? dateFormat
      : type === 'month'
        ? monthFormat
        : dateTimeFormat;
  if (isDayTime) {
    format = DAY_TIME;
    fn = formatDate;
  } else if (isHMS) {
    format = DAY_TIMES;
    fn = formatDate;
  }
  const isSrt = typeof result === 'string';
  if (readonly) {
    return rangedate
      ? getRangeDateVal(rangedate, keys, result, format, fn, pageData, readonly)
      : result
        ? isSrt
          ? result
          : fn(result, format)
        : null;
  }
  return rangedate
    ? getRangeDateVal(rangedate, keys, result, format, fn, pageData)
    : result
      ? isSrt
        ? moment(result, format)
        : moment(dateTimeFormat(result), format)
      : null;
}

// 获取范围日期真实值
function getRangeDateVal(
  rangedate,
  keys,
  result,
  format,
  fn,
  pageData,
  readonly
) {
  let dates = keys && result ? result : pageData;
  let start = dates[rangedate[0]];
  let end = dates[rangedate[1]];
  if (readonly) {
    return start ? fn(start, format) + '~' + fn(end, format) : null;
  }
  return start ? [moment(fn(start), format), moment(fn(end), format)] : null;
}

// 获取checkbox的真实值
function getRealCheckboxVal(result) {
  return result ? result.split(',') : [];
}

/**
 * 获取币种Data
 * return {
 *  'BTC': {
 *      'coin': 'BTC',
 *      'unit': '1e8',
 *      'name': '比特币',
 *      'type': '0',
 *      'status': '0'
 *  }
 *}
 */
export function getCoinData() {
  return JSON.parse(sessionStorage.getItem('coinData'));
}

/**
 * 获取币种列表
 * return [{
 *      key: 'BTC',
 *      value: '比特币'
 *}]
 */
export function getCoinList() {
  return JSON.parse(sessionStorage.getItem('coinList'));
}

// 获取币种unit
export function getCoinUnit(coin) {
  if (!coin) {
    console.log('coin不能为空');
    return;
  }
  var unit = getCoinData()[coin].unit;
  return unit;
}

export function getCoinType(coin) {
  if (!coin) {
    console.log('coin不能为空');
    return;
  }
  var type = getCoinData()[coin].type;
  return type;
}

/**
 *  判断传入参数的类型，以字符串的形式返回
 *  @obj：数据
 **/
export function dataType(obj) {
  if (obj === null) return 'Null';
  if (obj === undefined) return 'Undefined';
  return Object.prototype.toString.call(obj).slice(8, -1);
}

/**
 * 处理对象参数值，排除对象参数值为”“、null、undefined，并返回一个新对象
 **/
export function dealElement(obj) {
  let param = {};
  // if (obj === null || obj === undefined || obj === '') {
  if (obj === null || obj === undefined || obj === '') {
    return param;
  }
  for (let key in obj) {
    if (dataType(obj[key]) === 'Object') {
      param[key] = dealElement(obj[key]);
    } else if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
      param[key] = obj[key];
    }
  }
  return param;
}

/**
 * 去掉所有的html标记
 **/
export function delHtmlTag(str) {
  return str.replace(/<[^>]+>/g, '');
}

/**
 * 截取指定长度字符串，并添加...
 **/
export function textOverflowEllipsis(str, leng = 40) {
  if (str.length > leng) {
    str = str.substring(0, leng) + '...';
  }
  return str;
}

// 数组去重
export function deduplicationArray(data, field) {
  if (Array.isArray(data)) {
    let arr = [];
    let obj = {};
    for (let i = 0, len = data.length; i < len; i++) {
      if (!obj[data[i][field]]) {
        obj[data[i][field]] = '1';
        arr.push(data[i]);
      }
    }
    return arr;
  }
  return [];
}

// 处理产品分类数据
export function delProductClass(data) {
  if (Array.isArray(data)) {
    let arr = [];
    const parentArr = data.filter((item) => !item.fid);
    const childrenArr = data.filter((item) => item.fid);
    parentArr.forEach((item) => {
      const { id, name } = item;
      arr.push({
        ...item,
        key: id,
        title: name,
        children: []
      });
    });
    childrenArr.forEach((item) => {
      const { id, name, fid } = item;
      let i = -1;
      arr.forEach((aItem, index) => {
        if (+aItem.id === +fid) {
          i = index;
        }
      });
      if (i > -1) {
        arr[i].children.push({
          ...item,
          key: id,
          title: name,
          children: []
        });
      }
    });
    return arr;
  }
  return [];
}
/**
 * 查询字典对应的dValue
 */
export function findDsct(array, value) {
  return array.find((item) => item.dkey === value).dvalue;
}

/**
 * 格式化用户信息显示
 */
export function getUserInfo(data) {
  if (data.userInfo) {
    let name = data.userInfo.realName
      ? data.userInfo.realName
      : data.userInfo.nickname;
    return name + '（' + data.userInfo.mobile + '）';
  } else if (data.user) {
    let name = data.user.realName ? data.user.realName : data.user.nickname;
    return name + '（' + data.user.mobile + '）';
  }
  return '-';
}

export function renderNicknameAndMobile(record = {}) {
  const { nickname, mobile, realName } = record;
  let text = [];
  mobile && text.push(mobile);
  if (realName) {
    text.push(realName);
  } else if (nickname) {
    text.push(nickname);
  }
  if (text.length === 2) {
    return `${text[0]}（${text[1]})`;
  }
  return text[0] || '-';
}

export function setWebIcon(link) {
  let $favicon = document.querySelector('link[rel="icon"]');
  if ($favicon !== null) {
    $favicon.rel = 'icon';
    $favicon.type = 'image/x-icon';
    $favicon.href = link;
  } else {
    $favicon = document.createElement('link');
    $favicon.rel = 'icon';
    $favicon.type = 'image/x-icon';
    $favicon.href = link;
    document.head.appendChild($favicon);
  }
}

export async function setSystem(callback) {
  let headLogo = '';
  let loginPic = '';
  let webIcon = '';
  await fetch('/core/v1/config/public/list', {
    type: 'manage_client_login_config'
  }).then(async ossData => {
    headLogo = ossData.sys_logo_3;
    localStorage.setItem('welcome', ossData.welcome);
    localStorage.setItem('advise_browser', ossData.advise_browser);
    localStorage.setItem('contact_phone', ossData.contact_phone);
    localStorage.setItem('contact_phone2', ossData.contact_phone2);
    localStorage.setItem('company_name', ossData.company_name);
    await getAlToken().then(data => {
      if (data) {
        loginPic = formatImg(ossData.sys_logo_2);
        webIcon = formatImg(ossData.sys_logo_1);
        localStorage.setItem('alToken', JSON.stringify(data));
        localStorage.setItem('webIcon', webIcon);
        localStorage.setItem('loginPic', loginPic);
        localStorage.setItem('qiniu_domain', data.filePath);
        let link = formatImg(headLogo);
        setWebIcon(link);
        localStorage.setItem('headLogo', link);
        callback && callback(ossData);
      }
    });
    getCityList();
  });
}
export function debounce(fn, wait) {
  var timeout = null;
  return function () {
    if (timeout !== null) clearTimeout(timeout);
    timeout = setTimeout(fn, wait);
  };
}

// 格式化文本 没有显示为'-'
export function formatText(value) {
  if (isUndefined(value)) {
    return '-';
  }
  return value;
}

export function getYears() {
  const yearList = [];
  const monthList = [];
  const nowY = new Date().getFullYear();
  for (let i = nowY + 3; i >= 1990; i--) {
    yearList.push({
      label: i,
      value: i
    });
  }
  for (let i = 1; i <= 12; i++) {
    monthList.push({
      label: i,
      value: i
    });
  }
  return { yearList, nowY, monthList };
}

/**
 * buffer图片转换base64
 * @param buffer
 */
export function transformArrayBufferToBase64(buffer) {
  var binary = '';
  var bytes = new Uint8Array(buffer);
  for (var len = bytes.byteLength, i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

/**
 * excel表格单元格合并处理
 * @param data 表格数据
 * @param length 当前第几列
 * @param target 格式化到第几列结束
 */
export function formatExcelChildren(data, col, key) {
  // if (!data || !data.length || data.length <= 1) {
  //   return data;
  // }
  let list = [];
  let lastName = '';
  data.map((item, index) => {
    let nowIndex = '';
    if (`${item[0]}_${item[1]}_${item[2]}` === lastName) {
      nowIndex = list.length - 1;
    }
    if (!nowIndex && nowIndex !== 0) {
      list.push({
        key: `${item[0]}_${item[1]}_${item[2]}`,
        value: [...item.slice(0, col)],
        [key]: [item.slice(col)]
      });
      lastName = `${item[0]}_${item[1]}_${item[2]}`;
    } else {
      list[nowIndex][key].push(item.slice(col));
    }
  });
  return list;
}

export const copyValue = (str) => {
  var aux = document.createElement('input');
  aux.setAttribute('value', str);
  document.body.appendChild(aux);
  aux.select();
  document.execCommand('copy');
  document.body.removeChild(aux);
};

export function myTrim(str) {
  if (!str) return '';
  str = str.toString();
  if (String.prototype.trim) {
    return str.trim();
  }
  return str.replace(/^\s+(.*?)\s+$/g, '$1');
}

// 定制参数
export const moreTable = {
  '窗帘': [
    {
      code: 'type',
      name: '规格'
    },
    {
      code: 'mainCloth',
      name: '主布'
    },
    {
      code: 'spellCloth',
      name: '拼布'
    },
    {
      code: 'price',
      name: '布料单价',
      amount: true
    },
    {
      code: 'workmanship',
      name: '工艺'
    },
    {
      code: 'workmanshipPrice',
      name: '工艺单价',
      amount: true
    },
    {
      code: 'stereotype',
      name: '定型'
    },
    {
      code: 'stereotypePrice',
      name: '定型单价',
      amount: true
    },
    {
      code: 'wide',
      name: '宽(米)'
    },
    {
      code: 'high',
      name: '高(米)'
    },
    {
      code: 'actualMaterials',
      name: '实际用料(米)',
      number: true
    },
    {
      code: 'totalAmount',
      name: '总价'
    }
  ],
  '墙纸墙布': [
    {
      code: 'type',
      name: '规格'
    },
    {
      code: 'model',
      name: '型号'
    },
    {
      code: 'basicModePrice',
      name: '基膜单价',
      amount: true
    },
    {
      code: 'materialPrice',
      name: '材料单价',
      amount: true
    },
    {
      code: 'artificialAccessoryPrice',
      name: '人工辅料单价',
      amount: true
    },
    {
      code: 'actualMaterials',
      name: '实际用料(平方)',
      number: true
    },
    {
      code: 'totalAmount',
      name: '总价'
    }
  ],
  '成品帘': [
    {
      code: 'type',
      name: '规格'
    },
    {
      code: 'model',
      name: '型号'
    },
    {
      code: 'wide',
      name: '宽(米)'
    },
    {
      code: 'high',
      name: '高(米)'
    },
    {
      code: 'price',
      name: '单价',
      amount: true
    },
    {
      code: 'actualMaterials',
      name: '实际用料(米)',
      number: true
    },
    {
      code: 'totalAmount',
      name: '总价'
    },
    {
      code: 'remark',
      name: '备注'
    }
  ],
  '定制颜值包': [
    {
      code: 'type',
      name: '规格'
    },
    {
      code: 'color',
      name: '颜色'
    },
    {
      code: 'material',
      name: '材质'
    },
    {
      code: 'installFound',
      name: '安装基础'
    },
    {
      code: 'price',
      name: '单价',
      amount: true
    },
    {
      code: 'actualMaterials',
      name: '实际用料(平方)',
      number: true
    },
    {
      code: 'totalAmount',
      name: '总价'
    }
  ],
  '定制柜': [
    {
      code: 'type',
      name: '规格'
    },
    {
      code: 'productName',
      name: '产品名称'
    },
    {
      code: 'material',
      name: '材质(厘米)'
    },
    {
      code: 'price',
      name: '价格(元)',
      amount: true
    },
    {
      code: 'wide',
      name: '宽(米)'
    },
    {
      code: 'high',
      name: '高(米)'
    },
    {
      code: 'projectedArea',
      name: '投影面积(平方)',
      number: true
    },
    {
      code: 'totalAmount',
      name: '总价'
    },
    {
      code: 'remark',
      name: '备注'
    }
  ]
};

export function formatProductSpecs(dynamicList, data) {
  let specsS = [];
  const specsList = dynamicList.map(item => ({ title: item.name, code: item.code, list: [] }));
  const specsCombination = [];
  data.map((item, index) => {
    let list = [];
    specsList.map((it, i) => {
      const l = item.keyValueList.filter(t => t.childKey === it.code);
      if (l.length > 0) {
        const info = l[0];
        list.push(info.value);
        if (!specsList[i].list.includes(info.value)) {
          specsList[i].list.push(info.value);
        }
      }
    });
    if (item.recommendFlag === '1') {
      specsS = [...list];
    }
    specsCombination[index] = { id: index + 1, specs: [...list], info: item };
  });
  return { specsList, specsCombination, specsS };
}

export function formatCustomSpecs(skuProductSpecsList, isWj) {
  let specsList = [];
  let specsCombination = [];
  let specsS = [];
  skuProductSpecsList.map((item, index) => {
    const json = JSON.parse(item.jsonContent);
    let specs = [];
    Object.keys(json).map(key => {
      if (isWj && key === '组件') return;
      let length = null;
      const value = json[key];
      specsList.map((it, i) => {
        if (it.title === key) {
          length = i;
        };
      });
      if (length || length === 0) {
        let list = specsList[length].list;
        if (!(list.indexOf(value) >= 0)) {
          specsList[length].list = [...list, value];
        }
      } else {
        specsList.push({ title: key, list: [value] });
      }
      specs.push(json[key]);
    });
    if (item.recommendFlag === '1') {
      specsS = [...specs];
    }
    specsCombination[index] = { id: `${index + 1}`, specs: [...specs], info: item };
  });
  return { specsList, specsCombination, specsS };
}

export function getRealExcelTxt(list) {
  return list.map(item => {
    const type = typeof item;
    if (type === 'object' && item) {
      let text = '';
      (item.richText || []).map(it => {
        text += it.text;
      });
      item = text;
    }
    return item;
  });
}

export function getCustomId() {
  return `${Math.floor(Math.random() * 100000000)}`;
}

export function downLoadFile(fileName, url) {
  getBlob(url).then(blob => {
    const list = url.split('.');
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName + '.' + list[list.length - 1];
    link.click();
  });
}

export function getBlob(url) {
  return new Promise(resolve => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response);
      }
    };
    xhr.send();
  });
};