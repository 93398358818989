import { combineReducers } from 'redux';
import { user } from './redux/user';
import { menu } from './redux/menu';
import { modalDetail } from './redux/modal/build-modal-detail';

// 公告管理 - 公告编辑
import { publicNotices } from './redux/public/notices';
import { publicSystemNotices } from './redux/public/systemNotice';

// 帮助手册管理 - 公告编辑
import { publicHelpManual } from './redux/public/helpManual';
// 系统管理->菜单管理
import { MenuManagement } from './redux/system/menuManagement';
// 系统管理->操作日志
import { operationLog } from './redux/system/operationLog';

// 帮助手册管理 - 公告发送
import { publicHelpManualSend } from './redux/public/helpManualSend';
import { publicBannerUp } from './redux/public/bannerUp';
import { publicTestAction } from './redux/public/testAction';
import { publicMessage } from './redux/public/message';
// 消息管理->短信推送
import { publicAboutus } from './redux/public/aboutus';
import { systemRole } from './redux/system/role';
import { systemSysParam } from './redux/system/sysParam';
import { systemUpdate } from './redux/system/update';
import { systemUser } from './redux/system/user';
import { nodeDeploy } from './redux/system/nodeDeploy';
// 数据字典管理
import { DataDict } from './redux/system/dataDict';
// 文章管理/组别管理
import { systemArticle } from './redux/system/article';
// 文章管理-客服管理
import { customer } from './redux/system/customer';
// 文章管理 banner管理
import { publicBanner } from './redux/public/banner';
import { publicDetailList } from '@redux/public/publicDetailList';
// 业务管理
// 会员管理-会员查询
import { bizCommunityHouseInput } from '@redux/biz/communityHouse/input/input';
// kyc审核
import { bizCommunityHouseSurvey } from '@redux/biz/communityHouse/survey/survey';
// 会员管理-游离会员
import { bizCommunityHouseEstablishment } from '@redux/biz/communityHouse/establishment/establishment';

// 业务管理-推荐分佣
import { bizCommunityHouseOperate } from '@redux/biz/communityHouse/operate/operate';
import { communityHouseCompleted } from '@redux/biz/communityHouse/completed/completed';

// 业务管理-推荐分佣-每日社区查询
import { communityHouseHistory } from '@redux/biz/communityHouse/history/history';
import { communityHouseManagement } from './redux/biz/communityHouse/houseManagement/houseManagement';

// 公司结构
import { systemArticleClass } from './redux/system/articleClass';
// 职务岗位
import { customerService } from './redux/system/customerService';

// 公司管理-业务团队
import { businessTeam } from './redux/system/businessTeam';
import { businessTeamMember } from '@redux/system/businessTeamMember';
// 销售管理-销售合同
import { salesContract } from './redux/saleManagement/contractManagement/salesContractManagement';
import { cancellationContract } from './redux/saleManagement/contractManagement/cancellationContractManagement.js';
import { childContract } from './redux/saleManagement/contractManagement/childContractManagement';
import { historyContract } from './redux/saleManagement/contractManagement/historyContractManagement';
import { contractPaymentRecords } from '@redux/saleManagement/contractManagement/contractPaymentRecords';
import { contractJoin } from '@redux/saleManagement/contractManagement/contractJoin';
import { addProductRecords } from '@redux/saleManagement/contractManagement/addProductRecords';
// 销售管理-交付管理
import { interiorDelivery } from './redux/saleManagement/deliveryManagement/interiorDelivery';
import { clientDelivery } from './redux/saleManagement/deliveryManagement/clientDelivery';
import { preSaleTask } from './redux/saleManagement/preSalesManagement/preSaleTask';
import { salesPersonnel } from './redux/saleManagement/preSalesManagement/salesPersonnel';
// 销售管理-房源管理
import { housing } from '@redux/saleManagement/housingManagement/housing';
import { saleHousingSecondHouse } from '@redux/saleManagement/housingManagement/secondHouse';
// 方案设计
import { designContract } from './redux/saleManagement/design/contract';
import { crawlingContract } from '@redux/saleManagement/design/crawlingContract';
import { designFactory } from './redux/saleManagement/design/factory';
import { designFactoryAdd } from './redux/saleManagement/designFactory/add';
import { designFactoryEdit } from './redux/saleManagement/designFactory/edit';
import { designFactoryUpDown } from './redux/saleManagement/designFactory/upDown';
// 采购安装-SKU库
import { brand } from './redux/purchase/skuLibrary/brand';
import { business } from './redux/purchase/skuLibrary/business';
import { manufacturers } from './redux/purchase/skuLibrary/manufacturers';
import { productCategory } from './redux/purchase/skuLibrary/productCategory';
import { skuManagement } from './redux/purchase/skuLibrary/skuManagement';
import { skuCrawling } from './redux/purchase/skuLibrary/skuCrawling';
import { skuAudit } from './redux/purchase/skuLibrary/skuAudit';
import { skuEditCheck } from './redux/purchase/skuLibrary/skuEditCheck';
import { skuUpDownCheck } from './redux/purchase/skuLibrary/skuUpDownCheck';
import { skuAddCheck } from './redux/purchase/skuLibrary/skuAddCheck';
import { styles } from './redux/purchase/skuLibrary/styles';
import { skuDetonation } from './redux/purchase/skuLibrary/skuDetonation';
import { skuPurchase } from './redux/purchase/skuLibrary/skuPurchase';
import { skuExport } from './redux/purchase/skuLibrary/skuExport';
// 采购安装-定制产品
import { skuCustomAudit } from './redux/purchase/skuLibrary/skuCustomAudit';
// 采购安装-采购订单管理
import { purchaseOrder } from './redux/purchase/purchaseManagement/purchaseOrder';
import { logistics } from './redux/purchase/purchaseManagement/logistics';
import { placeAnOrder } from '@redux/purchase/purchaseManagement/placeAnOrder';
import { examineDetail } from '@redux/purchase/purchaseManagement/examineDetail';
import { plantInstallPic } from '@redux/purchase/purchaseManagement/plantInstallPic';
// 采购安装-管家服务
import { contract } from '@redux/purchase/steward/contract';
// 业务管理
import { inquiryBusiness } from './redux/business/onlineBusiness/inquiryBusiness';
import { commission } from './redux/business/onlineBusiness/commission';
import { haveDone } from './redux/business/onlineBusiness/haveDone';
import { merchandiser } from './redux/system/merchandiser';
import { material } from './redux/business/onlineBusiness/material';

// 动态属性关联表
import { relevanceManagement } from './redux/system/relevanceManagement';
import { dynamicProperties } from './redux/system/dynamicProperties';
import { dynamicValue } from './redux/system/dynamicValue';
// 财务管理 收付款管理
import { clientReceipt } from './redux/financeManagement/receivingManagement/clientReceipt';
import { clientRefund } from './redux/financeManagement/receivingManagement/clientRefund';
import { projectDividend } from './redux/financeManagement/receivingManagement/projectDividend';
import { venderReceipt } from './redux/financeManagement/receivingManagement/venderReceipt';
import { venderRefund } from './redux/financeManagement/receivingManagement/venderRefund';
// 财务管理 基础数据
import { bankingS } from './redux/financeManagement/basics/bankingS';
// 银行管理
import { bank } from '@redux/financeManagement/basics/bank';
// 财务管理 账单管理
import { gcBill } from './redux/financeManagement/bills/gcBill';
import { finaBill } from './redux/financeManagement/bills/finaBill';
import { refundBill } from './redux/financeManagement/bills/refundBill';
import { orderBill } from './redux/financeManagement/bills/orderBill';
// 财务管理 账户管理
import { companyAccount } from './redux/financeManagement/accountS/companyAccount';
import { onlineAccount } from './redux/financeManagement/accountS/onlineAccount';
import { onlineSysAccount } from './redux/financeManagement/accountS/onlineSysAccount';
import { projectAccount } from './redux/financeManagement/accountS/projectAccount';
import { venderAccount } from './redux/financeManagement/accountS/venderAccount';
import { saleAccount } from '@redux/financeManagement/accountS/saleAccount';
import { runWater } from '@redux/financeManagement/accountS/runWater';
import { ybBanks } from './redux/financeManagement/accountS/ybBanks';
// 课程管理
import { courseFile } from '@redux/courseManagement/course/courseFile';
import { courseType } from '@redux/courseManagement/course/courseType';
import { courseRecord } from '@redux/courseManagement/course/courseRecord';
// 客户管理
import { clientManagement } from '@redux/business/client/clientManagement';
import { electricity } from '@redux/business/client/electricity';
import { clientPool } from './redux/business/client/clientPool';

// 小程序管理
import { appletTeamIntention } from '@redux/applet/teamIntention';
import { appletSolicitationQuestionnaire } from '@redux/applet/solicitationQuestionnaire';
import { appletFeedback } from '@redux/applet/feedback';
import { appletPraiseShare } from '@redux/applet/praiseShare';
import { appletRepair } from '@redux/applet/repair';
import { appletComplaint } from '@redux/applet/complaint';
import { appletSatisfactionRecord } from '@redux/applet/satisfactionRecord';
import { appletComboReserve } from '@redux/applet/comboReserve';
import { appletCoupon } from '@redux/applet/coupon';
import { appletCouponConfig } from '@redux/applet/couponConfig';
import { appletPayRecord } from '@redux/applet/payRecord';
import { appletPromotion } from './redux/applet/promotion';
import { appletConfiguration } from './redux/applet/configuration';
import { appletPictureLibraries } from './redux/applet/pictureLibraries';
// 小程序团购
import { appletBulkTeam } from './redux/applet/bulkTeam';
import { appletBulkPeople } from './redux/applet/bulkPeople';

// 打卡管理
import { marketSignRecord } from '@redux/saleManagement/marketSign/record';
import { marketSignConfig } from '@redux/saleManagement/marketSign/config';
import { marketSignIdea } from '@redux/saleManagement/marketSign/idea';

// 交接管理
import { problems } from '@redux/saleManagement/handover/problems';

// 销售等级
import { level } from '@redux/saleManagement/hierarchy/level';
import { userLevel } from '@redux/saleManagement/hierarchy/userLevel';
import { marketStatistics } from '@redux/saleManagement/hierarchy/marketStatistics';
import { particulars } from '@redux/saleManagement/hierarchy/particulars';

// 统计
import { contractEarnings } from '@redux/financeManagement/earnings/contractEarnings';

// 关键数据
import { pivotalData } from '@redux/purchase/pivotal/pivotalData';

// 订单管理
import { cancelOrder } from '@redux/saleManagement/orderManagement/cancelOrder';
import { finishOrder } from '@redux/saleManagement/orderManagement/finishOrder';
import { waitGoods } from '@redux/saleManagement/orderManagement/waitGoods';
import { waitPayment } from '@redux/saleManagement/orderManagement/waitPayment';
import { haveGoods } from '@redux/saleManagement/orderManagement/haveGoods';

// 销售公司管理
import { company } from '@redux/saleManagement/saleCompany/company';

// 提现管理
import { financeWithdrawalApproval } from './redux/financeManagement/withdrawal/approval';
import { financeWithdrawalRecord } from './redux/financeManagement/withdrawal/record';
import { financeWithdrawalCompleted } from './redux/financeManagement/withdrawal/completed';
import { financeWithdrawalNopass } from './redux/financeManagement/withdrawal/nopass';
import { financeWithdrawalRejected } from './redux/financeManagement/withdrawal/rejected';
import { financeWithdrawalRelus } from './redux/financeManagement/withdrawal/rules';

// 仓储管理
import { waitStorage } from '@redux/purchase/storageManagement/waitStorage';
import { haveStorageGoods } from '@redux/purchase/storageManagement/haveStorageGoods';
import { waitStorageAffirm } from '@redux/purchase/storageManagement/waitStorageAffirm';
import { waitStorageGoods } from '@redux/purchase/storageManagement/waitStorageGoods';
import { storageFinish } from '@redux/purchase/storageManagement/storageFinish';
import { yunCangPoint } from './redux/purchase/storageManagement/yunCangPoint';
import { yunCangPeople } from './redux/purchase/storageManagement/yunCangPeople';
import { partPage } from './redux/purchase/storageManagement/partPage';
import { allStorage } from './redux/purchase/storageManagement/allStorage';
import { allPartPage } from './redux/purchase/storageManagement/allPartPage';
import { goodsPartPage } from './redux/purchase/storageManagement/goodsPartPage';
import { waitVerification } from './redux/purchase/storageManagement/waitVerification';
import { settlementReview } from './redux/purchase/storageManagement/settlementReview';
import { storageRelus } from './redux/purchase/storageManagement/rules';
import { ycSettlement } from './redux/purchase/storageManagement/ycSettlement';
import { ycWithdrawal } from './redux/purchase/storageManagement/ycWithdrawal';
// 销售公司申请
import { saleApply } from './redux/financeManagement/saleApplyMore/saleApply';

// 定制报价
import { customOffer } from './redux/purchase/purchaseManagement/customOffer';
import { merchantOffer } from './redux/purchase/purchaseManagement/merchantOffer';

// 易宝入驻管理
import { ybSettleCompany } from './redux/financeManagement/ybSettle/company';
import { ybSettleFactory } from './redux/financeManagement/ybSettle/factory';

// 阿里入驻管理
import { alSettleCompany } from './redux/financeManagement/alSettle/company';

// 易宝分账记录
import { ybWater } from './redux/financeManagement/ybManagement/water';

export default combineReducers({
  publicAboutus,
  user,
  menu,
  modalDetail,
  systemRole,
  systemUser,
  systemSysParam,
  systemUpdate,
  systemArticle,
  customer,
  // 公告管理 - 公告编辑
  publicNotices,
  publicSystemNotices,
  publicHelpManual,
  publicHelpManualSend,
  publicTestAction,
  publicBanner,
  publicBannerUp,
  publicMessage,
  publicDetailList,
  DataDict,
  MenuManagement,
  operationLog,
  systemArticleClass,
  customerService,
  bizCommunityHouseInput,
  bizCommunityHouseSurvey,
  bizCommunityHouseEstablishment,
  bizCommunityHouseOperate,
  communityHouseCompleted,
  communityHouseHistory,
  communityHouseManagement,
  businessTeam,
  businessTeamMember,
  salesContract,
  cancellationContract,
  childContract,
  historyContract,
  addProductRecords,
  contractPaymentRecords,
  contractJoin,
  interiorDelivery,
  clientDelivery,
  nodeDeploy,
  preSaleTask,
  salesPersonnel,
  designContract,
  designFactory,
  designFactoryAdd,
  designFactoryEdit,
  designFactoryUpDown,
  crawlingContract,
  brand,
  business,
  manufacturers,
  productCategory,
  skuManagement,
  skuDetonation,
  skuExport,
  skuPurchase,
  skuCrawling,
  skuAudit,
  skuEditCheck,
  skuAddCheck,
  skuUpDownCheck,
  styles,
  inquiryBusiness,
  commission,
  haveDone,
  material,
  merchandiser,
  relevanceManagement,
  dynamicProperties,
  dynamicValue,
  clientReceipt,
  clientRefund,
  projectDividend,
  venderReceipt,
  venderRefund,
  bankingS,
  bank,
  purchaseOrder,
  gcBill,
  finaBill,
  refundBill,
  orderBill,
  logistics,
  companyAccount,
  onlineAccount,
  onlineSysAccount,
  projectAccount,
  venderAccount,
  saleAccount,
  runWater,
  ybBanks,
  courseFile,
  courseType,
  courseRecord,
  clientManagement,
  clientPool,
  electricity,
  placeAnOrder,
  plantInstallPic,
  examineDetail,
  contract,
  housing,
  saleHousingSecondHouse,
  // 小程序管理
  appletTeamIntention,
  appletSolicitationQuestionnaire,
  appletFeedback,
  appletPraiseShare,
  appletRepair,
  appletComplaint,
  appletSatisfactionRecord,
  appletComboReserve,
  appletCoupon,
  appletCouponConfig,
  appletPayRecord,
  appletBulkTeam,
  appletBulkPeople,
  appletPromotion,
  appletConfiguration,
  appletPictureLibraries,
  // 打卡管理
  marketSignRecord,
  marketSignConfig,
  marketSignIdea,
  // 交接管理
  problems,
  // 销售等级
  level,
  userLevel,
  marketStatistics,
  particulars,
  contractEarnings,
  pivotalData,
  // 订单管理
  cancelOrder,
  finishOrder,
  waitGoods,
  waitPayment,
  haveGoods,
  // 销售公司
  company,
  // 提现管理
  financeWithdrawalApproval,
  financeWithdrawalRecord,
  financeWithdrawalCompleted,
  financeWithdrawalNopass,
  financeWithdrawalRejected,
  financeWithdrawalRelus,
  // 仓储管理
  waitStorage,
  haveStorageGoods,
  waitStorageAffirm,
  waitStorageGoods,
  storageFinish,
  yunCangPoint,
  yunCangPeople,
  saleApply,
  partPage,
  allStorage,
  allPartPage,
  goodsPartPage,
  waitVerification,
  skuCustomAudit,
  settlementReview,
  storageRelus,
  customOffer,
  merchantOffer,
  ycSettlement,
  ycWithdrawal,
  ybSettleCompany,
  ybSettleFactory,
  ybWater,
  alSettleCompany
});
