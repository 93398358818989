import fetch from 'common/js/fetch';
import { getRoleCode } from 'common/js/util';
import { ROOT_MENU_CODE } from 'common/js/config';

/**
 * 获取当前菜单拥有的按钮列表
 * @param parentKey
 */
export function getOwnerBtns(parentId) {
  return fetch('/core/v1/user/permission_none/permission/btn/' + parentId, {});
}

/**
 * 根据角色查询对应端菜单
 */
export function getRoleMenuTreeList(roleId) {
  return fetch('/core/v1/role/menu_tree/' + roleId);
}

/**
 * 根据角色查询对应端菜单
 */
export function getPlantRoleMenuTreeList(roleId) {
  return fetch('/core/v1/role/menu_tree_list', { roleId });
}

/**
 * 获取当前用户所有菜单
 */
export function getRoleMenuList() {
  return fetch('/core/v1/user/permission_none/permission/menu', {
    type: 'menu'
  });
}

/**
 * 获取角色的菜单和按钮
 */
export function getRoleMenuBtnList(id) {
  return fetch('/core/v1/role/permission_list/' + id);
}

/**
 * 列表查询接口
 * @param type 类型(query/operate)
 */
export function getActionList() {
  return fetch('/core/v1/action/list', {});
}

/**
 * 获取当前菜单所有的接口
 * @param menuId 菜单编号
 */
export function getMenuActionList(menuId) {
  return fetch('/core/v1/menu/action_list/' + menuId);
}

/**
 * 获取当前菜单所有的接口
 * @param menuId 菜单编号
 */
export function getMenuDetail(menuId) {
  return fetch('/core/v1/menu/detail/' + menuId);
}

/**
 * 为菜单分配接口
 * @param id 菜单编号
 * @param actionIdList 接口数组
 */
export function distributionAction({ id, actionIdList }) {
  return fetch('/core/v1/menu/allot_action', { id, actionIdList });
}

// 分配菜单权限
export function setRoleMenus(resourceIdList, id) {
  return fetch('/core/v1/role/allot_permission', {
    menuIdList: resourceIdList,
    roleId: id
  });
}

/**
 * 删除菜单
 * @param id 菜单编号
 */
export function deleteMenuById(id) {
  return fetch('/core/v1/menu/remove/' + id);
}

/**
 * 修改菜单
 * @param id 菜单编号
 */
export function editMenuById({
  id,
  name,
  parentId,
  url,
  orderNo,
  remark,
  type
}) {
  return fetch('/core/v1/menu/modify', {
    id,
    name,
    parentId,
    url,
    orderNo,
    remark,
    type
  });
}

/**
 * 修改菜单
 * @param id 菜单编号
 */
export function addMenu({ id, name, parentId, url, orderNo, remark, type }) {
  return fetch('/core/v1/menu/create', {
    id,
    name,
    parentId,
    url,
    orderNo,
    remark,
    type
  });
}

/**
 * 列表菜单
 * @params parentId
 * @params url
 * @params name
 * @params type
 */
export function getMenuList(params) {
  return fetch('/core/v1/menu/list', params);
}

/**
 * 列表菜单
 */
export function getMenuActionTreeList() {
  return fetch('/core/v1/user/client/menu');
}
