import fetch from 'common/js/fetch';
import { getDictList } from 'api/dict';
import { getCoinList } from 'api/coin';
import {
  setUser,
  setRoleInfo,
  getRoleCode,
  getUserId,
  getUserName,
  getUserRealName
} from 'common/js/util';
import { getCityList } from 'src/api/general';

const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGOUT = 'LOGOUT';
const LOAD_DATA = 'LOAD_DATA';
const LOADING = 'LOADING';
const CANCEL_LOADING = 'CANCEL_LOADING';

const initState = {
  fetching: false,
  redirectTo: '',
  msg: '',
  loginName: getUserName() || '',
  realName: getUserRealName() || '',
  roleCode: getRoleCode() || '',
  kind: ''
};

export function user(state = initState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state, msg: '' };
    case LOAD_DATA:
      return { ...state, ...action.payload, redirectTo: '/' };
    case LOGOUT:
      return { ...initState, redirectTo: '/login' };
    case LOADING:
      return { ...state, fetching: true };
    case CANCEL_LOADING:
      return { ...state, fetching: false };
    default:
      return state;
  }
}

// 登录成功
function loginSuccess(data) {
  return { type: LOGIN_SUCCESS, payload: data };
}

function doFetching() {
  return { type: LOADING };
}

export function cancelFetching() {
  return { type: CANCEL_LOADING };
}

// 获取用户信息成功
export function loadData(data) {
  setRoleInfo(data);
  return { type: LOAD_DATA, payload: data };
}

// 获取用户信息
export function getUser() {
  return (dispatch) => {
    dispatch(doFetching());
    _getUser()
      .then((data) => {
        dispatch(loadData(data));
        dispatch(cancelFetching());
      })
      .catch((msg) => {
        dispatch(cancelFetching());
      });
  };
}

// 登录
export function login({ loginName, loginPwd }) {
  return (dispatch) => {
    dispatch(doFetching());
    fetch('/core/v1/user/login/sys', {
      loginName,
      loginPwd,
      clientType: 'web',
      userKind: 'SYS'
    })
      .then((data) => {
        setUser(data);
        dispatch(loginSuccess());
        getDictList({ parentKey: 'language' }).then((data) => {
          if (data.length > 0) {
            sessionStorage.setItem('languageList', JSON.stringify(data));
          }
        });
        getCityList();
        // getCoinList().then((data) => {
        //   let coinList = [];
        //   let coinData = {};
        //   data.map((d) => {
        //     coinData[d.symbol] = {
        //       coin: d.symbol,
        //       unit: '1e' + d.unit,
        //       name: d.cname,
        //       type: d.type,
        //       status: d.status
        //     };
        //     coinList.push({
        //       key: d.symbol,
        //       value: d.cname
        //     });
        //   });
        //   window.sessionStorage.setItem('coinData', JSON.stringify(coinData));
        //   window.sessionStorage.setItem('coinList', JSON.stringify(coinList));
        // });
        return _getUser().then((data) => {
          window.lastTime = new Date().getTime();
          dispatch(cancelFetching());
          dispatch(loadData(data));
        });
      })
      .catch(() => {
        dispatch(cancelFetching());
      });
  };
}

function _getUser() {
  return fetch(`/core/v1/user/permission_none/my`, {});
}
