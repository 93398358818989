import { getRoleMenuList } from 'api/menu';
import { getRealUrl, getRootMenuCode, isUndefined } from 'common/js/util';

const SET_TOP_MENU_CODE = 'SET_TOP_MENU_CODE';
const SET_SUB_MENU_CODE = 'SET_SUB_MENU_CODE';
const SET_SUB_OPEN_CODE = 'SET_SUB_OPEN_CODE';
const CLEAR_SUB_OPEN_CODE = 'CLEAR_SUB_OPEN_CODE';
const RESTORE_SUB_OPEN_CODE = 'RESTORE_SUB_OPEN_CODE';
const SET_MENU_LIST = 'SET_MENU_LIST';

let preSubOpenCode = [];

const initState = {
  redirectTo: '',
  msg: '',
  menus: {},
  urlToMenu: {},
  top2SubObj: {},
  topMenuList: [],
  subMenuList: [],
  topMenuCode: '',
  subMenuCode: '',
  subOpenCode: []
};

export function menu(state = initState, action) {
  switch (action.type) {
    case SET_TOP_MENU_CODE:
      return {
        ...state,
        topMenuCode: action.payload,
        ...getSubCode(action.payload, state)
      };
    case SET_SUB_MENU_CODE:
      return { ...state, subMenuCode: action.payload };
    case SET_SUB_OPEN_CODE:
      return { ...state, subOpenCode: getSubOpenCode(action.payload, state) };
    case CLEAR_SUB_OPEN_CODE:
      return { ...state, subOpenCode: action.payload };
    case RESTORE_SUB_OPEN_CODE:
      return { ...state, subOpenCode: action.payload };
    case SET_MENU_LIST:
      return { ...state, ..._getMenuState(action.payload) };
    default:
      return state;
  }
}

export function setTopCode(id) {
  return { type: SET_TOP_MENU_CODE, payload: id };
}

export function setSubMenuCode(id) {
  return { type: SET_SUB_MENU_CODE, payload: id };
}

export function setSubOpenCode(id) {
  return { type: SET_SUB_OPEN_CODE, payload: id };
}

export function clearSubOpenCode() {
  return (dispatch, getState) => {
    preSubOpenCode = getState().menu.subOpenCode;
    dispatch({ type: CLEAR_SUB_OPEN_CODE, payload: [] });
  };
}

export function clearTopCode() {
  return (dispatch, getState) => {
    dispatch({ type: SET_TOP_MENU_CODE, payload: [] });
  };
}

export function clearSubMenuCode() {
  return (dispatch, getState) => {
    dispatch({ type: SET_SUB_MENU_CODE, payload: [] });
  };
}

export function restoreSubOpenCode() {
  return { type: RESTORE_SUB_OPEN_CODE, payload: preSubOpenCode };
}

function setMenuList(data) {
  return { type: SET_MENU_LIST, payload: data };
}

// 获取菜单列表
export function getMenuList(pathname) {
  return (dispatch) => {
    getRoleMenuList()
      .then((data) => {
        // let nameList = ['ipad端', '购物车', '首页', '套餐', '单品', '我的'];
        // data = data.filter((item) => {
        //   return nameList.indexOf(item.name) === -1;
        // });
        dispatch(setMenuList({ data, pathname }));
      })
      .catch(() => {});
  };
}

function _getMenuState({ data, pathname }) {
  let result = {
    topMenuList: [],
    topMenuCode: '',
    subMenuList: [],
    subMenuCode: '',
    subOpenCode: [],
    top2SubObj: {},
    menus: {},
    urlToMenu: {}
  };
  let newList = getFilterList(result, data);
  createMenus(newList, result);
  sortSubMenus(result);
  if (pathname !== '/') {
    var pathArr = pathname.split('/').filter((v) => v);
    let len = pathArr.length;
    let realPath = pathArr.slice(0, len).join('/');
    realPath = '/' + realPath + '.htm';
    let menu = Object.values(result.menus).find((v) => v.url === realPath);
    let realPath1 = pathArr.slice(0, len - 1).join('/');
    realPath1 = '/' + realPath1 + '.htm';
    let menu1 = Object.values(result.menus).find((v) => v.url === realPath1);
    if (menu || menu1) {
      menu = menu || menu1;
      result.subMenuCode = menu.id;
      result.subOpenCode = [menu.parentId];
      result.topMenuCode = result.menus[menu.parentId].parentId;
      result.subMenuList = result.top2SubObj[result.topMenuCode];
      if (!result.subMenuCode) {
        result.subMenuCode = result.subMenuList[0].children
          ? result.subMenuList[0].children[0].id
          : '';
      }
      if (!result.subOpenCode.length) {
        result.subOpenCode = [result.subMenuList[0].id];
      }
    } else {
      result.redirectTo = '/';
    }
  }
  return result;
}

function getFilterList(result, data) {
  let newList = [];
  data.forEach((v) => {
    v.id = v.id.toString();
    v.orderNo = v.orderNo.toString();
    if (v.parentId) {
      v.parentId = v.parentId.toString();
      result.menus[v.id] = v;
      // if (v.parentId === getRootMenuCode()) {
      if (v.parentId === data[0].id) {
        result.topMenuList.push(v);
        result.top2SubObj[v.id] = [];
      } else {
        newList.push(v);
      }
    }
  });
  return newList;
}

function createMenus(newList, result) {
  newList.forEach((v) => {
    v.url = getRealUrl(v.url);
    result.urlToMenu[v.url] = v;
    let pCode = v.parentId;
    if (result.top2SubObj[pCode]) {
      if (!result.top2SubObj[pCode].find((i) => i.id === v.id)) {
        result.top2SubObj[pCode].push(v);
      }
    } else {
      let pList = result.top2SubObj[result.menus[pCode].parentId] || [];
      let pIdx = pList.findIndex((v) => v.id === pCode);
      if (pIdx > -1) {
        pList[pIdx].children = pList[pIdx].children || [];
      } else {
        result.menus[pCode].children = [];
        pList.push(result.menus[pCode]);
        pIdx = pList.length - 1;
      }
      pList[pIdx].children.push(v);
    }
  });
}

function sortSubMenus(result) {
  for (let key in result.top2SubObj) {
    result.top2SubObj[key].sort((a, b) => {
      return a['orderNo'].localeCompare(b['orderNo']);
    });
  }
}

function getSubCode(id, state) {
  return {
    subOpenCode:
      id && state.top2SubObj[id] && state.top2SubObj[id].length > 0
        ? [state.top2SubObj[id][0].id]
        : [],
    subMenuCode:
      id && state.top2SubObj[id] && state.top2SubObj[id].length > 0
        ? state.top2SubObj[id][0].children
          ? state.top2SubObj[id][0].children[0].id
          : ''
        : '',
    subMenuList:
      id && state.top2SubObj[id] && state.top2SubObj[id].length > 0
        ? state.top2SubObj[id]
        : []
  };
}

function getSubOpenCode(id, state) {
  let list = state.subOpenCode.slice();
  let idx = list.findIndex((v) => v === id);
  if (idx > -1) {
    list.splice(idx);
  } else {
    list.push(id);
  }
  return list;
}
